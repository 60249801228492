import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { read } from "hooks/useSession.ts";
import { PostOrdersRequestBody } from "types/api/orders/postOrders";
import { models } from "types/api/viewModels.ts";
import { DesignerViewModel } from "data/api/v1";

interface NewOrderDesigner {
	designer?: DesignerViewModel | string;
	customDesigner?: string;
}

export interface SubmittingNewOrderSlice {
	newOrder: PostOrdersRequestBody | undefined;
	reviewOrderDisplay: PostOrdersRequestBody | undefined;
	draftOrder: models["DraftOrderViewModel"] | undefined;
	draftError: any;
	designer: NewOrderDesigner | undefined;
}

const storageState: SubmittingNewOrderSlice | undefined = read("_submittingNewOrder");

const newOrder = storageState ? { ...storageState, newOrder: storageState?.newOrder } : undefined;

export const initialState: SubmittingNewOrderSlice = newOrder || {
	newOrder: undefined,
	draftOrder: undefined,
	draftError: undefined,
	reviewOrderDisplay: undefined,
	designer: undefined
};

const submittingNewOrderSlice = createSlice({
	name: "submittingNewOrder",
	initialState,
	reducers: {
		submittingNewOrder: (state, action: { payload: PostOrdersRequestBody }) => {
			state.newOrder = {
				...action.payload,
				tandemOrderId: state.newOrder?.tandemOrderId ?? action.payload.tandemOrderId
			};
		},
		addGlobalAttributeToOrder: (state, action: { payload: models["PendingOrderConfigurationViewModel"] }) => {
			let configurations;
			let draftConfigurations;
			if (state.newOrder?.configurations) {
				configurations = [...state.newOrder.configurations, action.payload];
			} else {
				configurations = [action.payload];
			}
			if (state.draftOrder?.configurations) {
				draftConfigurations = [...state.draftOrder.configurations, action.payload];
			} else {
				draftConfigurations = [action.payload];
			}
			state.newOrder = {
				...state.newOrder,
				configurations: configurations
			};
			state.draftOrder = {
				...state.draftOrder,
				configurations: draftConfigurations
			};
		},
		reviewOrderDisplay: (state, action) => {
			state.reviewOrderDisplay = action.payload;
		},
		clearOrderToBeSubmitted: (state) => {
			state.newOrder = undefined;
			state.draftOrder = undefined;
			state.draftError = undefined;
			state.reviewOrderDisplay = undefined;
			state.designer = undefined;
		},
		addConfigsToDraftOrder: (state, action) => {
			state.draftOrder = {
				...state.draftOrder,
				configurations: [...(state?.draftOrder?.configurations ?? []), ...action.payload]
			};
		},
		updateDraftOrder: (state, action: { payload: models["DraftOrderViewModel"] }) => {
			state.draftOrder = {
				...action.payload,
				tandemOrderId: state.newOrder?.tandemOrderId ?? action.payload.tandemOrderId
			};
		},
		updateDraftError: (state, action) => {
			state.draftError = action.payload;
		},
		clearDraftError: (state) => {
			state.draftError = undefined;
		},
		updateDraftName: (state, action) => {
			state.draftOrder = {
				...state.draftOrder,
				draftName: action.payload
			};
		},
		updateDraftAccountNumber: (
			state,
			action: PayloadAction<{
				productLineCode: string | undefined | null;
				newAccountNumber: models["CustomerAccountViewModel"] | null;
			}>
		) => {
			const { productLineCode, newAccountNumber } = action.payload;
			if (productLineCode !== undefined && state.draftOrder?.configurations && productLineCode) {
				state.draftOrder.configurations.forEach((configuration) => {
					if (configuration.globals?.productLine?.code === productLineCode) {
						configuration.accountId = newAccountNumber === null ? null : newAccountNumber?.accountId;
						configuration.accountName = newAccountNumber === null ? null : newAccountNumber?.accountName;
						configuration.accountNumber =
							newAccountNumber === null ? null : newAccountNumber?.accountNumber;
					}
				});
			}
		},
		updateDraftBillToNumber: (
			state,
			action: PayloadAction<{
				productLineCode: string | undefined | null;
				newBillToNumber: models["BillToViewModel"] | undefined | null;
			}>
		) => {
			const { productLineCode, newBillToNumber } = action.payload;
			if (productLineCode !== undefined && state.draftOrder?.configurations && productLineCode) {
				state.draftOrder.configurations.forEach((configuration) => {
					if (configuration.globals?.productLine?.code === productLineCode) {
						configuration.billToId = newBillToNumber?.billToId ?? "";
					}
				});
			}
		},
		updateDesignerNumber: (state, action: PayloadAction<DesignerViewModel | string | undefined>) => {
			state.designer = {
				...state.designer,
				designer: action.payload
			};
		},
		updateCustomDesignerNumber: (state, action: PayloadAction<string | undefined>) => {
			state.designer = {
				...state.designer,
				customDesigner: action.payload
			};
		}
	}
});

export const submittingNewOrderActions = submittingNewOrderSlice.actions;
export default submittingNewOrderSlice.reducer;
