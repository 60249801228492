export const designerNumberText = "Designer";

export const BillToTitle = "BILL TO";
export const ShipToTitle = "SHIP TO";
export const SubtotalText = "Subtotal";

export const NoDesignerText = "Designer not identified";

export const AccountNumberText = "Account #";
export const signOutButtonText = "Sign Out";
export const OriginalOrderText = "Original Order";

export const myOrdersLinkText = "My Orders";
export const ViewAssociatedOrdersText = "View Associated Orders";

export const searchHeaderButtonText = "Search";
export const InactiveAccounts = "Inactive Accounts";

export const searchBarHelperText = "Search by Account #, Order #, PO #, Truck #, or Job Name";
export const searchOrdersText = "Search Orders";
export const minCharCountText = "Please enter at least 3 characters.";
export const myOrdersText = "My Orders";
export const NoOrdersFoundHeader = "We're sorry, we couldn't find any orders!";

export const NoOrderDetailsFoundHeader = "Uh oh, order not found!";

export const OrderDetailHeaderText = "Projects";

export const BackorderProjectsHeaderText = "Projects with Backorder Items";
export const NoChargeOrder = "NO CHARGE ORDER";
export const NoOrdersListSubheader = "To see orders on this list, please try:";
export const NoOrdersListSubSubheader =
	"Please contact your Sales Rep if the problem persists and you are certain of your search criteria.";

export const NoOrderDetailsFoundSubheader =
	"The order you requested could not be located in our system. Please contact Care if additional help is needed.";

export const ContactCareAndCheckDetailsErrorText =
	"Something doesn’t look correct. To proceed with your order please check that your order details and shipping information are correct before submitting again.  Please contact Care should the issue continue.";

export const NoOrdersFoundFirstBullet = "Placing an order";
export const NoOrdersFoundSecondBullet = "Adjusting your filters";
export const NoOrdersFoundThirdBullet = "Checking your search spelling";

export const ReturnToSignInButtonText = "Return to Sign In";

export const ReturnToMyOrdersButtonText = "Return to My Orders";

export const ReturnToDashboardButtonText = "Return to Dashboard";

export const PendingApprovalHeader = "Your account is pending approval!";

export const PendingApprovalDescription =
	"Your Sales Rep is working on getting your access ready for you. You should be able to log in soon, but if you need access more quickly, please reach out to them directly. ";

export const ApprovedAccountHeader = "We're sorry, there is a problem with your account access";

export const ApprovedAccountDescription =
	"Looks like we need to check your account access before processing your request. Please contact your Sales Rep to remedy your access.";

export const AccessDeniedHeader = "Not sure what is happening! It appears your access is denied.";

export const AccessDeniedDescription = "If you already have an account, please contact your Sales Rep for access.";

export const OrderSummary400ErrorHeader = "We're sorry, something went wrong with your search.";

export const OrderSummary403ErrorHeader = "Whoops!";

export const OrderSummaryDefaultErrorHeader = "Uh oh, something went wrong!";

export const ServiceUnavailableHeader = "Uh oh, something went wrong!";

export const OrderSummary400ErrorText = "Please try again shortly or contact your Sales Rep should the issue continue.";

export const OrderSummary403ErrorText =
	"Seems as if you need permission to see what you are requesting. Please contact your Sales Rep to remedy your access.";

export const OrderSummaryDefaultErrorText =
	"This is embarrassing! The server is temporarily unable to process your request. Please try again shortly by refreshing your page or contact your Sales Rep should the issue continue.";

export const ServiceUnavailableText =
	"This is embarrassing! The server is temporarily unable to process your request. Please try again shortly by refreshing your page or contact your Sales Rep should the issue continue.";

export const NotFoundHeader = "Uh oh, page not found!";

export const NotFoundText =
	"The page you are looking for appears to have been moved, deleted, or doesn’t exist. Try accessing what you need again or contact your Sales Rep if additional help is needed.";
export const UnavailableDataPlaceholderText = "--";
export const OrderDateRangeLabel = "Order Date Range";
export const OrderDateText = "Order Date";
export const OrderNumberText = "Order #";
export const DoorStyleText = "Door Style";
export const FinishText = "Finish";
export const RequestedDateText = "Requested Date";
export const CabinetCountText = "Cab #";
export const AccessoryCountText = "Acc #";
export const CubeCountText = "Cube #";
export const QuantityText = "Qty";
export const QuantityFullText = "Quantity";
export const ModificationsText = "Modifications";
export const ExtendedPriceText = "Extended Price";
export const OnBackorderText = "On Backorder";
export const ShipmentText = "Shipment ";
export const ItemAffectedBackorderText = "This item is affected by a backorder";
export const OnBackorderAccordionText =
	"Items in this order have been placed on backorder and may arrive separately from the original order placed. Backorder item(s) are placed on priority production and will be shipped to you as soon as possible.";
export const TrackingNumberText = "Tracking #";
export const TruckTrackingNumberText = "Truck/Tracking #";
export const TruckNumberText = "Truck #";
export const PartOnBackorderText = "Part on Backorder";
export const ItemAffectedText = "Item Affected";
export const ETAText = "ETA:";
export const ETATextOnly = "ETA";
export const CarrierText = "Carrier";
export const LineItemsInOrderText = "Line Items";

export const OrderDetailFlyoutHeader = "Item Details";

export const OrderDetailFlyoutAlertHeaderParts = "Items on Backorder";

export const OrderDetailFlyoutAlertHeaderCabinet = "Whole Cabinet on Backorder";

export const OrderDetailFlyoutBackorderPartsAlertText =
	"Items of your order are on backorder and have been placed on priority production. They may ship separately from the rest of your order.";

export const OrderDetailFlyoutBackorderCabinetAlertTextPartOne = "This cabinet";

export const OrderDetailFlyoutBackorderCabinetAlertTextPartTwo =
	"is on backorder and may ship separately from the rest of your order.";

export const OrderDetailFlyoutQualityBackorderedLabel = "Quantity backordered";

export const OrderDetailFlyoutArrivalDateLabel = "Arrival date";

export const OrderDetailFlyoutPartsOnBackorderLabel = "Parts on Backorder";

export const FlyoutItemNumberLabel = "Item Number";

export const FlyoutDoorStyleLabel = "Door Style";

export const FlyoutConstructionLabel = "Construction";

export const FlyoutHingeLabel = "Hinge";

export const OrderDetailFlyoutQuantityLabel = "Quantity";

export const OrderDetailFlyoutModificationsDetailsLabel = "Details";

export const OrderDetailFlyoutModificationsValueLabel = "Value";

export const OrderDetailFlyoutModificationsPriceLabel = "Price";

export const OrderDetailFlyoutListPriceLabel = "List Price";

export const OrderDetailFlyoutExtendedPriceLabel = "Extended Price";

export const FlyoutModificationsLabel = "Modifications";

export const FlyoutFinishNameLabel = "Finish Name";

export const FlyoutStandardDimensionsLabel = "Standard Dimensions";

export const OrderDetailFlyoutDepthLabel = "Depth";

export const OrderDetailFlyoutWidthLabel = "Width";

export const OrderDetailFlyoutHeightLabel = "Height";
export const ShippingEnRouteText = "Items below are en route to the shipping address with carrier";
export const ItemText = "Item";
export const ItemsText = "Items";
export const SingleCardTruckText = "Truck";

export const SingleCardTrackingText = "Tracking";

export const WithinLastDaysLabelText = "Last 90 Days";
export const CustomDateText = "Custom Date Range";
export const ApplyButtonText = "Set Date Range";
export const OrderReplacementsText = "Order Replacements";

export const OrderReplacementsJobName = "Job Name";

export const OrderReplacementsPONumber = "PO #";

export const OrderReplacementsOrderType = "Order Type";

export const ReturnToOriginalOrderText = "Return to Original Order";

export const ReplacementsFlyoutSelectPartText = "Select Parts";

export const ReplacementsFlyoutOriginalItemText = "Original Item";

export const ReplacementsSelectAPartError = "Please make a selection to add to your order.";

export const PartsAvailableForReplacementText = "Parts Available for Replacement";

export const ReplacementsAddToOrderButtonText = "ADD TO ORDER";
export const ReplacementsCancelButtonText = "CANCEL";
export const SaveButtonText = "SAVE";
export const CancelButtonText = "CANCEL";

export const NoReplacementPartsText =
	"There are no replacement parts for this item. Please order the original item for replacement.";
export const ReplaceOriginalItemText = "Replace Original Item";
export const OrderLimitMessage =
	"You are seeing the top 1,000 orders in your search range. If you don’t see the order you are are looking for, try adjusting your search or date range.";
export const OrderLimitMessageBottom = "If you do not see your order,  try adjusting your search or date range above.";
export const EmptyFlyoutHeaderText = "Your Order Is Empty";
export const NoLinkedOrdersHeaderText = "No Orders Found!";
export const LinkedOrdersErrorHeaderText = "Uh oh, we're having trouble loading your orders.";
export const EmptyFlyoutHelperText = "Looks like you haven't added anything to your order!";
export const EmptyLinkedOrdersHelperText =
	"Looks like this order does not have any orders associated with it at this time. If you place an Add-on or Replacement order, they will appear in this list.";
export const ErrorLinkedOrdersHelperText =
	"The server is temporarily unable to retrieve your associated orders. Please try searching for your orders in 'My Orders' or check back later.";
export const ShoppingCartContinueButtonText = "Continue Selecting Parts";
export const ShoppingCartProceedButtonText = "Proceed With Order";
export const YourOrderText = "Your Order";
export const AssociatedOrdersHeaderText = "Associated Orders";
export const RemoveText = "Remove";
export const PartsAddedText = "Part(s) Added";
export const OrderTypeText = "Order Type";
export const JobNameText = "Job Name";
export const JobNameOptionalText = "Job Name (optional)";
export const PONumberText = "PO #";
export const PONumberRequiredText = "Please enter a PO #.";
export const DesignerNumberText = "Designer #";
export const CommentText = "Comment";
export const OrderReplacementText = "Order Replacement";
export const ItemsAddedText = "Items Added to your Cart";
export const ItemAddedText = "Item Added to your Cart";
export const ReplacementReasonText = "Reason for Replacement";
export const PleaseDescribeTheProblemReplacementText =
	"Please describe what problems you are experiencing with your order";
export const NoChargeOrderText = "NO CHARGE ORDER";

export const RequiredFieldsText = "Required Field (*)";

export const ShippingMethodText = "Shipping Method";
export const AddressText = "Address";
export const EstimatedDeliveryText = "Estimated Delivery";

// --------- Replacements Review -----------
export const ReplacementReviewText = "Review and Order Replacement";

export const SelectReplacementReasonText = "Select Reason for Replacement";
export const DescribeYourProblemText = "Describe the Problem";

export const PleaseSelectReplacementReasonErrorText = "Please select the reason for replacement";
export const PleaseDescribeYourProblemErrorText = "Please describe your problem";
export const ReasonDamage = "Damage";
export const ReasonFinishOrSurfaceDefects = "Finish or Surface Defects";
export const ReasonWrongOrMissingProduct = "Incorrect Item or Missing Product";
export const UnauthorizedErrorHeader = "Uh oh, is that you?";
export const UnauthorizedErrorDescription = "Let's just verify it's you by signing in again.";
export const UnauthorizedErrorDisclaimer = "If you continue to run into problems, please contact your Sales Rep.";

export const UnauthorizedErrorCode = "(Error Code: 401)";

export const AuthErrorErrorCode = "(Error Code: 500)";
export const MaxQuantitySelectedText =
	"You have selected the maximum number of parts available to be replaced for this item. A replacement of the original item cannot be requested.";
export const MaxQuantityPartsSelectedText =
	"You have selected to replace all of the original items ordered. A replacement of additional parts cannot be requested.";

export const ReplacementReason = "Replacement Reason";
export const ReviewOrderReplacement = "Review and Order Replacement";
export const EditText = "Edit";
export const ReplaceOriginalItem = "Replace Original Item";

export const OrderListErrorsWarningMessage = "You have orders in statuses that may require attention";

export const SelectThreeImagesText =
	"For the quickest resolution, we encourage you to provide up to 3 images of the damage to help our quality team prevent future issues.";
export const SupportedFilesHeader = "Supported Files:";
export const SupportedFiles = "JPG, JPEG, PNG, PDF";
export const AttachingText = "ATTACHING";
export const AttachFile = "Attach File";
export const AttachedFiles = "Attached Files";
export const MaxFileSizeHeader = "Max File Size:";
export const MaxFileSize = "10MB";
export const MaxUploadsError = "Max limit of 3 image files exceeded. Please select no more than 3 files to upload.";

export const DragFile = "Drag your file(s) here or";
export const BrowseFiles = "Browse Files";
export const PerImage = "per image";

export const ReplacementReviewOrderDetailsHeader = "Order Details";

export const ReplacementReviewOEditLink = "Edit";

export const ReplacementReviewShippingHeader = "Ship To";

export const ReplacementReviewShippingMethodHeader = "Shipping Method";

export const ReplacementReviewShippingToHeader = "Ship To";

export const ReplacementReviewContactInformationHeader = "Contact Information";

export const ReplacementReviewReplacementReasonText = "Replacement Reason";

export const ReplacementReviewUploadedImagesText = "Uploaded Images";

export const ReplacementReviewPhoneLabel = "Phone";

export const ReplacementReviewEmailLabel = "Email";

export const ReplacementReviewSubmitOrder = "Submit Order";

export const ReplacementReviewShippingLabelNotes = "Shipping Label Notes";

// File Validation
export const FileSuccessText = "Success!";
export const FileTooLargeText = "File too large! The maximum upload file size is 10MB. Please try again.";
export const FileTooSmallText = "File too small! The minimum upload file size is 1KB. Please try again.";
export const FileIncorrectTypeText = "Unsupported file! Image must be JPG, JPEG, PNG, or PDF. Please try again.";
export const FileDuplicateText = "A file with this name has already been attached. Please try again.";

export const BackText = "Back";
export const ContinueText = "Continue";

export const ReplacementConfirmationHeader = "Thank you!";

export const ReplacementConfirmationSubheader =
	"Your order has been submitted. We will get started on your order right away and you will see updates in Tandem soon. If further information is required to process your request we will reach out to you directly.";

export const ReplacementConfirmationLabel = "Replacement Order";
export const ReplacmentConfirmationOrderNumber = "Order #";

export const ReplacmentConfirmationOrderPending = "Pending";
export const ReplacementShipToAddressSubheaderText = "Address";
export const ReplacementShipToDeliveryContactSubheaderText = "Delivery Contact";
export const ReplacementShipToShippingMethodText = "Shipping Method";
export const ReplacementShipToOrderContactText = "Order Contact";
export const ReplacementShipToFormUsingInfoText = "We'll use this information to contact you about your order.";
export const ReplacementsShipToFormShippingLabelNotes = "Shipping Label Notes";
export const ReplacementsShipToFormShippingLabelSubheader =
	"This information will be printed on your shipping label for your reference only";

// Exit Replacements Warning
export const AreYouSureText = "Are you sure you'd like to leave this order?";
export const SaveDraftBeforeLeavingText = "Would you like to save this draft before leaving?";
export const ItemsWillClearText = "The items in your order will not be saved if you leave this screen.";
export const YesExitOrder = "YES, EXIT ORDER";
export const NoContinueOrdering = "NO, CONTINUE ORDERING";
export const ReplacementReviewBillTo = "Bill To";

export const ReplacementReviewAddress = "Address";

export const ReplacementReviewNoShipAddress = "Please provide a shipping address to proceed with your order.";

export const ReplacementReviewDeliveryContact = "Delivery Contact";

export const ReplacementReviewDeliveryInstructions = "Delivery Instructions";

// Replacement Order Submitted Banner on OrderDetailsHeader
export const ReplacementOrderSubmitted = "Replacement Order Submitted";
export const ReplacementOrderSubmittedText =
	"Updates will typically be available in Tandem within 10 minutes. If any further information is needed to process your order, we will reach out directly.";

export const ReplacementPost400ErrorHeader = "We're sorry, we couldn't process your order.";

export const ReplacementPost400ErrorHeader4000Errors =
	"We're sorry, we couldn't process your order. Please check that the following information is correct:";

export const ReplacementPost400ErrorDescription =
	"Something doesn't look correct. To proceed with your order please check that your order details and shipping information are correct before submitting again.";

export const NewOrderCouldNotGeneratePriceEstimateHeader = "We're sorry, we couldn’t generate your price estimate.";
export const NewOrderEstimateErrorSubHeader =
	"Something doesn't look correct. Please try the following to proceed with your order:";
export const NewOrderEstimateErrorGenerateAgainBullet = "Generate the price estimate again";
export const NewOrderEstimateErrorUpdateLineItemsBullet = "Review and update line item(s) that have errors";
export const NewOrderEstimateErrorReviewGuidelinesBullet =
	"Review product guidelines in the Product Spec Book (make sure you are reviewing the latest version)";
export const NewOrderEstimateErrorReUploadBullet = "Review and re-upload your CSV file";
export const NewOrderPleaseContactCare =
	"If you have tried all of the steps above and the problem continues, please contact Care.";
export const NewOrderPleaseSubmitRequestText =
	"If you have tried all of the steps above and the problem continues, please";
export const SubmitTandemRequestText = "submit a Tandem Support Request";
export const NewOrderEstimateErrorSaveAsADraftBullet = "Save your order as a draft and try again later";
export const NewOrderCheckYourCSVBullet = "Review your CSV file to resolve any errors and try importing again";
export const NewOrderContactAccountManagerSubheader =
	"Something doesn't look right. Please contact your Account Manager to proceed with your order";
export const SelectAccountAndBillTo = "Ensure all Account #s and Bill To #s are selected";
export const NewDraft400ErrorText =
	"Something doesn’t look correct. Before submitting again, please try the following to proceed with your order:";

export const OrderNotFoundHeader = "Uh oh, we're having trouble retrieving your Original order";

export const OrderNotFoundDescription =
	"The server is temporarily unable to process your replacement request. Please try again or contact your Sales Rep if the problem persists.";

export const ReplacementResubmitHeader = "Hooray! Your order is already being processed.";

export const ReplacementResubmitDescription =
	"Looks like your order has already been submitted and is currently being processed. You will see updates in Tandem within 10 minutes.";

export const AddNewAddressLink = "+ Add Address";

// Form Error Text
export const PhoneNumberFormError = "Please enter a valid Phone Number.";
export const RequestDeliveryDateFormError =
	"Please select a valid request date between 5-90 business days from today's date.";
export const EmailFormError = "Please enter a valid email address.";
export const CharacterLimitExceeded = "Limit Exceeded.";
export const ReplacementShipToFormDeliveryText = "Delivery";
export const ReplacementShipToFormPickupText = "Pickup";

export const PhoneLabelOptional = "Phone (optional)";
export const PhoneLabel = "Phone";
export const EmailLabel = "Email (optional)";
export const RequiredFieldNotice = "Required Field (*)";

// Add New Address Form
export const AddNewAddressTitle = "Add New Address";
export const VerifyLoadingMessage = "Hang tight while we add your new address...";
export const VerifyAddressConfirmYourAddress = "Confirm Your Address";
export const VerifyAddressWeSuggest = "We Suggest";
export const VerifyAddressYouEntered = "Address You Entered";
export const VerifyAddressErrorMessage =
	"We were not able to verify the address you entered. Please edit or confirm your address. Orders with incorrect addresses may be delayed or lost.";
export const FullNameLabel = "Full Name";
export const FullNameHelperText = "This can be the name of your business or contact.";
export const AddressHelperText = "Orders cannot be shipped to a PO Box.";
export const AddressLabel = "Address";
export const Address2Label = "Apt, Suite, etc. (optional)";
export const CityLabel = "City";
export const StateLabel = "State";
export const ZipLabel = "ZIP";
export const CountyLabel = "County";
export const SaveAndContinueButton = "Save and Continue";
export const EditAddressButton = "Edit Address";

export const ListboxKeepTyping = "Keep typing address to display results";
export const ContactInformationTitle = "Contact Information";
export const ContactInformationNotice = "This will be the contact information associated with your new address.";
export const ModalCancel = "Cancel";
export const ModalSave = "Save";

export const FullNameFormError = "Please enter a Full Name.";
export const AddressFormRequiredError = "Please enter a Street Address.";
export const AddressFormError = "Please enter a Street Address. Orders cannot be shipped to a PO Box.";
export const Address2FormError = "Orders cannot be shipped to a PO Box.";
export const CityFormError = "Please enter a City.";
export const StateFormError = "Please select a State";
export const ZipFormError = "Please enter a ZIP.";
export const CountyFormError = "Please enter a County.";

export const FieldError = "Form Field Error";

// Export PDF and CSV Text
export const exportPDFText = "Export as PDF";
export const exportCSVText = "Export to Excel";
export const exportOrdersText = "EXPORT ORDERS";
export const OrdersText = "ORDERS";
export const exportOrderDetailsText = "EXPORT ORDER DETAILS";
export const LargeModalPDFText = {
	PDFTooLarge:
		"The following orders exceed the maximum export limit of 800 orders for PDFs. If you would like to export your orders as a PDF, please narrow your results.",
	ProceedWithExcelPrompt:
		"There is no limit on exporting an excel file. Would you like to proceed with an excel export?",
	CloseButtonLabel: "Close and try again",
	LargeFileTitle: "Large File",
	ExportExcelOption: "Export to Excel"
};
export const NoAccountText =
	"It looks like your account does not have permission to order this product line. Please contact your Account Manager.";
export const OrderDetailsPDFOrderStatus = "ORDER STATUS";
export const OrderDetailsPDFEta = "ESTIMATED DELIVERY";
export const ExportConfirmationText = "EXPORT CONFIRMATION";
export const OrderConfirmationText = "Order NewOrderConfirmation";
export const GeneratedText = "Generated: ";
export const OrderAddOns = "Order Add-Ons";
export const AddOnOrder = "ADD-ON ORDER";
export const StatusUpdatedText = "Status Updated: ";
export const AddOnText = "AddOn";

// Navigation Constants
export const NavigationOrderPlaceNewOrder = "PLACE A NEW ORDER";

// ---------------NewOrders---------------
export const ImportFileText = "IMPORT FILE";
export const ManualOrderEntryText = "Manual Order Entry";
export const CreateYourItemSubtitleText = "Create your item list by adding individual items.";
export const AddItemText = "ADD ITEM";
export const FindOriginalOrderText = "FIND ORIGINAL ORDER";
export const PlaceANewOrderText = "Place a New Order";
export const StartOver = "Start Over";
export const NoExitOrder = "NO, EXIT ORDER";
export const YesSaveDraft = "YES, SAVE DRAFT";
export const DraftANewOrderText = "Draft New Order";
export const ImportCSVText = "Import CSV";
export const CreateYourItemText = "Create your item list using 2020, ProKitchen, etc.";
export const SupportedFilesText = "Supported Files: CSV";
export const CSVText = "CSV";
export const DownloadLatestText = "Download the latest catalogs";
export const HereText = "here.";
export const ReplacementOrAddOnText = "Order Replacements or Add-ons";
export const FindOriginalText = "Find the original order to start a replacement or add-on.";
export const SelectAccountText = "Select Account #";
export const SelectBillToText = "Select Bill To #";
export const AllFieldsRequiredText = "All fields required.";
export const FileNameText = "File Name";
export const StartOverText = "START OVER";
export const ContinueButtonText = "CONTINUE";
export const ImportAddOnErrorText =
	"An Add-On order can only include Product Lines that are associated with the same Account # and Bill To as the Original order. Please try again with a Product Line that is compatible.";
export const MVPNewOrderText = "New Orders (Beta)";
export const SelectAccountAndBillingText = "Select Account and Billing Information";
export const SearchItemText = "Search by item # or item description";
export const SearchPartText = "Search by part # or part description";
export const DesignerText = "If you don't find the designer number you're looking for, contact your Sales rep.";
// Welcome massage for care user
export const defaultMessageForCareUserText = "Welcome, start searching to see your orders!";
export const HangTightTitle = "Hang tight while we process your order!";
export const HangTightSubtitle =
	"Your order has been received and we are working on processing your request. Updates will typically be available in Tandem within 10 minutes. Your line items will be available to view soon.";
export const NoLineItemsAvailableText = "No line items available";

export const AddressRequiredText = "Please select an Address.";

export const EstimatedDeliveryToolTipDesc = "Estimated arrival at the shipping address";

export const PAGE_ERROR = "There was an Error, please try again.";
export const AUTH_ERROR = "There was an Authentication Error, please log in again.";

export const DeliveryText = "Delivery";
export const PickupText = "Pickup";

export const LoadingLabel = "Loading";

// ---------------AddOns---------------
export const BuildYourOrderText = "How would you like to build your order?";
export const OriginalOrderDetailsText = "ORIGINAL ORDER DETAILS";
export const ProductLineText = "Product Line";

// --------------- User Emulation ---------------
export const UserEmulationModalTitle = "Select User Using Their Email";
export const UserEmulationEmailLabel = "Select User Email";
export const UserEmulationSwitchLabel = "Emulation";
export const UserEmulationConfirmButton = "Emulate";
export const UserEmulationBackButton = "Back to Care Mode";
export const UserEmulationFormErrorText = "Please select a User Email";
export const UserEmulationNowViewing = "Now Viewing";
export const UserEmulationChange = "Change";
