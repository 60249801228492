import {
	NewOrderFlowButtons,
	NewOrdersContent,
	NewOrdersContentContainer,
	NewOrdersLayoutContainer
} from "components/NewOrders/NewOrders.styles";
import {
	NewOrderBack,
	NewOrderContactCare,
	NewOrderGenericError,
	NewOrderNotCorrect,
	NewOrderProcessError,
	NewOrderReviewPageTitle,
	NewOrderSubmitOrder
} from "components/NewOrders/constants";
import NewOrdersSubheader from "components/NewOrders/NewOrdersSubheader/NewOrdersSubheader";
import ReviewOrderDetails from "components/NewOrders/Review/ReviewOrderDetails";
import ReviewOrderList from "components/NewOrders/Review/ReviewOrderList";
import NewOrdersHeader from "components/NewOrders/NewOrdersHeader/NewOrdersHeader";
import { type FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { useCreateOrderMutation } from "features/api/newOrderApi.ts";
import ContentLoadingSkeleton from "pages/OrderPages/components/LoadingSkeleton/ContentLoadingSkeleton.tsx";
import { submittingNewOrderActions } from "features/reducers/newOrder/submittingNewOrder.ts";
import { useDeleteDraftOrderMutation } from "features/api/orderApi.ts";
import { ReviewNewOrderErrorBannerStyles } from "components/Replacements/ErrorBannerStyles";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { PostOrdersRequestBody } from "types/api/orders/postOrders";
import { AddOnsProps } from "../BuildOrderPage/BuildOrderPage";

const ReviewOrderPage: FC<AddOnsProps> = ({ isAddOn }) => {
	const navigate = useNavigate();
	const [submitOrder, { error, isLoading }] = useCreateOrderMutation();
	const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
	const dispatch = useDispatch();
	const [deleteDraftOrder] = useDeleteDraftOrderMutation();
	const draftId = useSelector((state: RootState) => state.submittingNewOrder.draftOrder?.draftOrderId);
	const draftOrder = useSelector((state: RootState) => state.submittingNewOrder.draftOrder);
	const params = useParams();
	const customDesigner = useSelector((state: RootState) => state.submittingNewOrder.designer?.customDesigner);
	const designer = useSelector((state: RootState) => state.submittingNewOrder.designer?.designer);

	const newOrderDetails: PostOrdersRequestBody | undefined = useSelector(
		(state: RootState) => state.submittingNewOrder.newOrder
	);

	const handleBack = () => {
		dispatch(submittingNewOrderActions.clearDraftError());
		navigate(`/${isAddOn ? "add-ons" : "new-order"}/shipping-details${isAddOn ? "/" + params.orderId : ""}`);
	};

	const handleContinue = async () => {
		setShowSkeleton(true);
		let newOrder;
		if (newOrderDetails) {
			newOrder = {
				...newOrderDetails,
				designerNumber: customDesigner ?? (typeof designer !== "string" ? designer?.designerNumber : undefined)
			};
		}
		await submitOrder(newOrder)
			.unwrap()
			.then(() => {
				navigate(`/${isAddOn ? "add-ons" : "new-order"}/confirmation${isAddOn ? "/" + params.orderId : ""}`);
				if (draftId && draftOrder?.type !== "Template") {
					deleteDraftOrder(draftId ?? "");
				}
				dispatch(submittingNewOrderActions.clearDraftError());
			})
			.catch(() => {
				setShowSkeleton(false);
				window.scrollTo(0, 0);
			});
	};

	if (isLoading || showSkeleton) {
		return <ContentLoadingSkeleton dataTestId="new-order-review-loading-skeleton" />;
	}

	return (
		<NewOrdersLayoutContainer>
			<NewOrdersHeader
				draftOrder={draftOrder}
				isReview
				isPDFExport
				isAddOn={isAddOn}
			/>
			<NewOrdersContentContainer>
				<NewOrdersContent>
					<NewOrdersSubheader
						title={NewOrderReviewPageTitle}
						dataTestId="new-order-review-order-label"
						handleSearch={() => {}}
						draftOrder={draftOrder}
						isAddOn={isAddOn}
					/>
					{error && (
						<ReviewNewOrderErrorBannerStyles data-testid="api-error-banner-header">
							<div>
								<div>
									<ErrorOutline />
								</div>
								<div>
									<h6>{NewOrderProcessError}</h6>
									<span>{NewOrderNotCorrect}</span>
									<ul>
										<li>
											{"errors" in error &&
											Array.isArray(error.errors) &&
											error.errors[0]?.message
												? error.errors[0].message
												: NewOrderGenericError}
										</li>
									</ul>
									<span>{NewOrderContactCare}</span>
									{"status" in error && error.status && <span>({error.status} Error)</span>}
								</div>
							</div>
						</ReviewNewOrderErrorBannerStyles>
					)}
					<ReviewOrderDetails isAddOn={isAddOn} />
					<ReviewOrderList />
					<NewOrderFlowButtons>
						<Button
							data-testid="new-order-review-back-button"
							variant="text"
							onClick={handleBack}
						>
							{NewOrderBack}
						</Button>
						<Button
							data-testid="new-order-review-submit-order-button"
							onClick={handleContinue}
							variant="contained"
						>
							{NewOrderSubmitOrder}
						</Button>
					</NewOrderFlowButtons>
				</NewOrdersContent>
			</NewOrdersContentContainer>
		</NewOrdersLayoutContainer>
	);
};

export default ReviewOrderPage;
