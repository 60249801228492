import { useEffect, useMemo, useState } from "react";
import { Button, Divider, Drawer } from "@mui/material";
import {
	CarrierText,
	EmptyLinkedOrdersHelperText,
	ErrorLinkedOrdersHelperText,
	EstimatedDeliveryText,
	EstimatedDeliveryToolTipDesc,
	exportOrderDetailsText,
	LinkedOrdersErrorHeaderText,
	myOrdersLinkText,
	NoLinkedOrdersHeaderText,
	OrderAddOns,
	OrderDateText,
	OrderNumberText,
	OrderReplacementsText,
	OrdersText,
	PONumberText,
	ReplacementOrderSubmitted,
	ReplacementOrderSubmittedText,
	StatusUpdatedText,
	TrackingNumberText,
	TruckNumberText,
	UnavailableDataPlaceholderText,
	ViewAssociatedOrdersText
} from "constants/text";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import InsertLink from "@mui/icons-material/InsertLink";
import { ORDER_STATUS_REASONS } from "constants/orderStatusHeaderStatusReasons";
import { dateFormat } from "utils/date";
import { convertToTitleCase } from "utils/string";
import { useNavigate } from "react-router-dom";
import { brandCheck } from "utils/order";
import ExportSnackbar from "../ExportFile/ExportSnackbar";
import ExportButtons from "../ExportFile/ExportButtons";
import useOrderDetailsPDFGenerator from "hooks/useOrderDetailsPDFGenerator";
import { OrderDetailViewModel, OrderStatus, OrderType } from "data/api/v1";
import { generateMyOrderDetailsExcel } from "./helpers/MyOrderDetailsExportsHelper";
import { useGetUserDetailsQuery } from "features/api/userApi.ts";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import {
	ButtonContainer,
	CarrierInfoContainer,
	DeliveryContent,
	DetailsSummaryCardLogo,
	DrawerContainer,
	EstimatedDelivery,
	EstimatedDeliveryToolTip,
	NumberOfOrders,
	OrderDateColumn,
	OrderDetailsGrayBox,
	OrderDetailsHeaderContent,
	OrderDetailsHeaderContentDetails,
	OrderDetailsHeaderJobName,
	OrderDetailsHeaderWrapper,
	OrderDetailsMyOrdersLink,
	OrderETA,
	OrderNumberPoNumberDivider,
	OrderNumberPoNumberText,
	OrderNumberPoNumberWrapper,
	PillContainer,
	ReplacementOrderSubmittedBanner,
	RequestedDateContainer,
	StatusContainer,
	StatusReason,
	StatusWrapper,
	StyledChip
} from "./OrderDetailsHeader.styles";
import { useGetLinkedOrdersQuery } from "features/api/orderApi.ts";
import FlyoutHeader from "./AssociatedOrders/FlyoutHeader";
import IndividualLinkedOrderCard from "./AssociatedOrders/IndividualLinkedOrderCard";
import EmptyErrorStates from "./AssociatedOrders/EmptyErrorStates";
import { ShoppingCartFlyoutScrollableWrapper } from "../Replacements/ShoppingCartFlyout.styles";
import useWindowSettings from "hooks/useWindowSettings";
import Link from "components/Common/Link/Link";
import Tooltip from "@mui/material/Tooltip";
import useSnackbarEffect from "hooks/useSnackbarEffect";
import { useGetAccountsByIdQuery } from "features/api/accountApi";
import { SingleCardOrderStatusPillChip } from "../SingleCard/SingleCard.styles.ts";
import { NewOrderRequestedDeliveryDate } from "../NewOrders/constants.ts";

const OrderDetailsHeader = ({ orderDetail }: Props) => {
	const formattedOrderType = convertToTitleCase(orderDetail.orderType);
	const [statusReasonMessage, setStatusReasonMessage] = useState("");
	const [statusReason, setStatusReason] = useState("");
	const [defaultColor, setNewColor] = useState("");
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const navigateTo = useNavigate();
	const { data: linkedOrders, error } = useGetLinkedOrdersQuery(orderDetail?.orderId ?? "");
	const linkedOrdersCount = linkedOrders?.length ? linkedOrders.length : 0;
	const { isMobile } = useWindowSettings();

	const replacementOrders: string[] = useSelector((state: RootState) => state.submittedReplacementOrders.orders);
	const hasReplacementOrder = useMemo(() => {
		return replacementOrders.find((originalOrderId) => originalOrderId === orderDetail.orderId);
	}, [replacementOrders, orderDetail.orderId]);

	const { pdfData, generateOrderDetailsPDF } = useOrderDetailsPDFGenerator(orderDetail);
	const { snackbarOpen } = useSnackbarEffect(pdfData.loading);

	const handleAssociatedOrders = () => {
		setIsOpen((prevState) => !prevState);
	};

	const handleOnClose = () => {
		setIsOpen((prevState) => !prevState);
	};

	const showEstimatedDeliveryText = (orderStatus?: string) => {
		return (
			orderStatus !== OrderStatus.DELIVERED &&
			orderStatus !== OrderStatus.CANCELLED &&
			orderStatus !== OrderStatus.INVOICED
		);
	};

	const orderDetailsHeaderStatusUpdate = orderDetail.statusUpdateDate
		? orderDetail.statusUpdateDate
		: orderDetail.updatedAt;

	useEffect(() => {
		ORDER_STATUS_REASONS.forEach(
			(reason) => reason.status === orderDetail.status && setStatusReasonMessage(reason.description)
		);

		ORDER_STATUS_REASONS.forEach(
			(reason) => reason.status === orderDetail.status && setStatusReason(reason.formattedStatus)
		);

		ORDER_STATUS_REASONS.forEach(
			(reason) => reason.status === orderDetail.status && setNewColor(reason.statusColor)
		);
	});

	const handleOrderReplacements = () => {
		return navigateTo(`/replacements/${orderDetail.orderId}`);
	};

	const { data: userDetails } = useGetUserDetailsQuery();
	const { data: accounts } = useGetAccountsByIdQuery(orderDetail.orderId ?? "");

	const goToOrderDetails = (orderId: string) => {
		setIsOpen(false);
		return navigateTo(`/details/${orderId}`);
	};

	const orderUserAccountDetail = accounts?.find((account) => account.accountNumber === orderDetail.accountNumber);
	const isReplacementEligible =
		orderDetail.isReplacementEligible && (orderUserAccountDetail?.isActive || userDetails?.user?.role === "CARE");

	const carrierInfo: { label: string; info: string | string[] | null | undefined; dataTestId: string }[] = [
		{
			label: CarrierText,
			info: orderDetail.shippingDetails?.carrierName,
			dataTestId: "orderDetailsHeader-carrierName"
		},
		{
			label: TruckNumberText,
			info: orderDetail.shippingDetails?.truckNumber,
			dataTestId: "orderDetailsHeader-truckNumber"
		},
		{
			label: TrackingNumberText,
			info: orderDetail.shippingDetails?.trackingNumber?.split(","),
			dataTestId: "orderDetailsHeader-trackingNumber"
		}
	];

	const goToAddOnOrder = () => {
		navigateTo(`/add-ons/${orderDetail.orderId}`);
	};

	// To clarify for subsequent work isAddOnEligible value in the order details response represents order.Status != OrderStatus.DELETED && order.Status != OrderStatus.CANCELLED && order.AccountIsActive && order.BillToIsActive;

	return (
		<OrderDetailsHeaderWrapper>
			<OrderDetailsMyOrdersLink>
				<Link
					to="/"
					hasBackIcon
					id="order-details-my-orders-link"
					data-id="order-details-my-orders-link"
					data-testid="orderDetailsHeader-myOrdersLink"
				>
					{myOrdersLinkText}
				</Link>
				<ExportButtons
					largePDF={false}
					generatePDF={generateOrderDetailsPDF}
					buttonHeader={exportOrderDetailsText}
					generateExcel={() => generateMyOrderDetailsExcel(orderDetail)}
				/>
				<ExportSnackbar
					open={snackbarOpen}
					fileLoading={pdfData.loading}
					largePDF={false}
				/>
			</OrderDetailsMyOrdersLink>

			<OrderDateColumn data-testid="orderDetails-orderDateColumn">
				<div className="overline">
					{OrderDateText.toUpperCase()}:{" "}
					{dateFormat(orderDetail?.orderDate ?? "") ?? UnavailableDataPlaceholderText}
				</div>

				<DetailsSummaryCardLogo
					data-testid="orderDetailsHeader-brandLogo"
					parentBrand={orderDetail.parentBrand}
					src={
						brandCheck(orderDetail.parentBrand)
							? `/assets/manufacture_logos/${orderDetail.parentBrand}.png`
							: "/assets/tandem_logos/cwg_logo.png"
					}
					alt={
						brandCheck(orderDetail.parentBrand) ? String(orderDetail.parentBrand) : "CabinetworksGroup Logo"
					}
				/>
			</OrderDateColumn>

			<OrderDetailsHeaderContent>
				<OrderDetailsHeaderContentDetails>
					<OrderDetailsHeaderJobName>
						<h4
							data-testid="order-details-header-job-name"
							lang="en"
						>
							{orderDetail.jobName ? orderDetail.jobName : orderDetail.poNumber}
						</h4>

						<StyledChip
							label={formattedOrderType}
							data-testid="orderDetailsHeader-orderTypeChip"
						/>
					</OrderDetailsHeaderJobName>

					<Button
						variant="text"
						onClick={handleAssociatedOrders}
						data-testid="associated-orders-link"
						aria-label="associated-orders-link"
						startIcon={
							<InsertLink
								aria-label="insert-link-icon"
								data-testid="insert-link-icon"
							/>
						}
						sx={{ fontFamily: "Gibson Medium, sans-serif", fontSize: 13 }}
					>
						{ViewAssociatedOrdersText.toUpperCase()}
					</Button>
					<Drawer
						anchor="right"
						open={isOpen}
						onClose={handleOnClose}
					>
						<DrawerContainer>
							<FlyoutHeader handleOnClose={handleOnClose} />
							{linkedOrdersCount > 0 && (
								<>
									<Divider />
									<NumberOfOrders data-testid="number-of-linkedOrders">
										{linkedOrdersCount} {OrdersText}
									</NumberOfOrders>
								</>
							)}
							{error && (
								<EmptyErrorStates
									helperText={ErrorLinkedOrdersHelperText}
									image="/assets/UnauthorizedSadImage.svg"
									titleText={LinkedOrdersErrorHeaderText}
								/>
							)}

							{!error && linkedOrdersCount === 0 && (
								<EmptyErrorStates
									helperText={EmptyLinkedOrdersHelperText}
									image="/assets/NoOrdersSadBoxImage.svg"
									titleText={NoLinkedOrdersHeaderText}
								/>
							)}

							{linkedOrdersCount > 0 && (
								<ShoppingCartFlyoutScrollableWrapper
									isMobile={isMobile}
									isReplacementsPage={false}
									isDetailsPage
								>
									{linkedOrders?.map(
										(associatedOrder) =>
											associatedOrder && (
												<IndividualLinkedOrderCard
													key={associatedOrder.orderId}
													associatedOrder={associatedOrder}
													goToOrderDetails={goToOrderDetails}
												/>
											)
									)}
								</ShoppingCartFlyoutScrollableWrapper>
							)}
						</DrawerContainer>
					</Drawer>
				</OrderDetailsHeaderContentDetails>
				<ButtonContainer>
					{isReplacementEligible && (
						<Button
							fullWidth
							variant="contained"
							data-testid="order-replacements"
							data-id="order-replacements-button"
							onClick={() => handleOrderReplacements()}
							aria-label="order replacements"
						>
							{OrderReplacementsText}
						</Button>
					)}
					{userDetails?.user?.createOrdersEnabled &&
						orderDetail.orderType !== OrderType.CANCELLED &&
						orderDetail.isAddOnEligible && (
							<Button
								fullWidth
								variant={isReplacementEligible ? "outlined" : "contained"}
								data-testid="order-add-ons"
								onClick={goToAddOnOrder}
								aria-label="order add ons"
							>
								{OrderAddOns}
							</Button>
						)}
				</ButtonContainer>
			</OrderDetailsHeaderContent>

			{orderDetail.orderType !== OrderType.BILLING_ONLY && (
				<OrderDetailsGrayBox>
					<StatusWrapper>
						<StatusContainer>
							<OrderNumberPoNumberWrapper data-testid="orderDetails-orderNumberColumn">
								<OrderNumberPoNumberText>{OrderNumberText}</OrderNumberPoNumberText>
								{orderDetail.orderNumber}
							</OrderNumberPoNumberWrapper>
							<OrderNumberPoNumberDivider>
								<Divider orientation="vertical" />
							</OrderNumberPoNumberDivider>
							<OrderNumberPoNumberWrapper data-testid="orderDetailsHeader-PONumber">
								<OrderNumberPoNumberText>{PONumberText}</OrderNumberPoNumberText>
								{orderDetail.poNumber}
							</OrderNumberPoNumberWrapper>
						</StatusContainer>

						<span
							className="overline"
							data-testid="orderDetailsHeader-statusUpdatedDate"
						>
							{StatusUpdatedText}
							{orderDetail.statusUpdateDate !== null || orderDetail.updatedAt !== null
								? dateFormat(String(orderDetailsHeaderStatusUpdate))
								: UnavailableDataPlaceholderText}
						</span>
					</StatusWrapper>

					<Divider />

					<PillContainer>
						{statusReason && (
							<SingleCardOrderStatusPillChip
								label={convertToTitleCase(statusReason)}
								orderStatus={statusReason}
								data-testid="orderDetailsHeader-status"
							/>
						)}

						<StatusReason
							statusReasonColor={defaultColor}
							data-testid="orderDetailsHeader-statusReasonMessage"
						>
							{statusReasonMessage}
						</StatusReason>
					</PillContainer>

					{orderDetail.status !== OrderStatus.CANCELLED && (
						<DeliveryContent>
							<div>
								{showEstimatedDeliveryText(orderDetail?.status) && (
									<EstimatedDelivery
										className="subtitle1"
										data-testid="orderDetailsHeader-estimatedDelivery"
									>
										{EstimatedDeliveryText}
										<Tooltip
											data-testid="orderDetailsHeader-estimatedDelivery-toolTip"
											title={EstimatedDeliveryToolTipDesc}
											placement="top"
										>
											<EstimatedDeliveryToolTip>
												<InfoOutlined data-testid="estimated-delivery-tooltip-icon" />
											</EstimatedDeliveryToolTip>
										</Tooltip>
									</EstimatedDelivery>
								)}
								{orderDetail.eta && (
									<OrderETA data-testid="orderDetailsHeader-eta">{orderDetail.eta}</OrderETA>
								)}
								{orderDetail.requestedDeliveryDate && (
									<RequestedDateContainer>
										{NewOrderRequestedDeliveryDate}
										<div data-testid="orderDetails-requestedDeliveryDateColumn">
											{dateFormat(orderDetail.requestedDeliveryDate)}
										</div>
									</RequestedDateContainer>
								)}
							</div>

							<CarrierInfoContainer>
								{carrierInfo.map(
									(info) =>
										info.info && (
											<div
												className="body2"
												data-testid={info.dataTestId}
												key={info.label}
											>
												<b className="subtitle2">{info.label}</b>
												{info.info}
											</div>
										)
								)}
							</CarrierInfoContainer>
						</DeliveryContent>
					)}
				</OrderDetailsGrayBox>
			)}

			{hasReplacementOrder && (
				<ReplacementOrderSubmittedBanner data-testid="orderDetailsHeader-replacement-submitted-banner">
					<InfoOutlined />
					<div>
						<p>{ReplacementOrderSubmitted}</p>
						<p>{ReplacementOrderSubmittedText}</p>
					</div>
				</ReplacementOrderSubmittedBanner>
			)}
		</OrderDetailsHeaderWrapper>
	);
};

interface Props {
	orderDetail: OrderDetailViewModel;
}

export default OrderDetailsHeader;
